import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  appName: 'Kinogram',
  unauthenticatedBaseUrl: '/',
  authenticatedBaseUrl: '/',
  localStoragePrefix: 'base-template-',
  graphqlUrl: 'https://dev-kinogram.develtio.dev/api/graphql',
  graphqlUrlWs: 'wss://dev-kinogram.develtio.dev/api/graphql',
  apiUrl: 'https://dev-kinogram.develtio.dev',
};
