import { ViewportScroller, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { appInitializerFactory } from '@factories/app-initializer.factory';
import { HttpLoaderFactory } from '@factories/multi-translate-http-loader.factory';
import { GraphQLModule } from '@graphql/graphql.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@services/app-injector.service';
import { LanguageService } from '@services/language.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import { NotificationComponent } from './_components/notification/notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
registerLocaleData(localePl);

const primeNgConfigFactory = (primeConfig: PrimeNGConfig) => () => {
  primeConfig.ripple = true;
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: NotificationComponent,
      positionClass: 'toast-top-center',
    }),
    GraphQLModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: primeNgConfigFactory,
      deps: [PrimeNGConfig],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    CookieService,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (lang: LanguageService) => {
        return lang.getCurrentLanguage();
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector, router: Router, viewportScroller: ViewportScroller) {
    AppInjector.setInjector(injector);
    router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      if (e.position) {
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position!);
        });
      } else if (e.anchor) {
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor!);
        });
      }
    });
  }
}
