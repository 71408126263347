import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  template: `
    <div class="notification" [ngClass]="options.toastClass">
      <div class="notification-gradient"></div>
      <div class="notification-body">
        <div class="notification-icon">
          <svg-icon [src]="'assets/img/' + options.toastClass + '.svg'"></svg-icon>
        </div>
        <div class="notification-message">{{ message }}</div>
      </div>
      <div class="notification-close">
        <svg-icon src="assets/img/toast-close.svg"></svg-icon>
      </div>
    </div>
  `,
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent extends Toast {
  constructor(
    override toastrService: ToastrService,
    override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
