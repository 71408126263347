import { HttpHeaders } from '@angular/common/http';
import { ApolloLink } from '@apollo/client/core';
import { VOUCHER_AUTH_TOKEN_KEY } from '@configs/storage.config';

/**
 * Sets auth token into the header
 * @param authStorageService Your auth token provider
 */
export const getAuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const jwt = localStorage.getItem(VOUCHER_AUTH_TOKEN_KEY);
    let httpHeaders = new HttpHeaders();

    if (jwt) httpHeaders = httpHeaders.set('Authorization', `Bearer ${jwt}`);

    operation.setContext({ headers: httpHeaders });

    return forward(operation);
  });
};
