import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment as env } from '@env';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { getAuthMiddleware } from './middlewares/auth.middleware';

/**
 * Creates and returns the ApolloLink configured connection
 * @param httpLink HttpLink from apollo-angular/http
 * @returns ApolloLink from @apollo/client/core
 */
function getLink(httpLink: HttpLink): ApolloLink {
  // Creates an http link:
  const http = httpLink.create({
    uri: env.graphqlUrl,
  });

  // Creates a WebSocket link:
  const ws = new WebSocketLink({
    uri: env.graphqlUrlWs,
    options: {
      reconnect: false,
    },
  });

  // Using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  return split(
    // Splits based on operation type
    ({ query }) => {
      const def = getMainDefinition(query);
      return def?.kind === 'OperationDefinition' && def?.operation === 'subscription';
    },
    ws,
    http,
  );
}

/**
 * Provides Apollo Configuration
 * @param httpLink from apollo-angular/http
 * @param authStorageService your Auth token provider
 * @param router from @angular/router
 * @returns ApolloClientOptions from @apollo/client/core
 */
export function createApollo(httpLink: HttpLink, router: Router): ApolloClientOptions<any> {
  return {
    resolvers: {},
    link: ApolloLink.from([getAuthMiddleware(), getLink(httpLink)]),
    cache: new InMemoryCache(),
  };
}

/**
 * Main GraphQL Configuration
 */
@NgModule({
  imports: [BrowserModule, HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Router],
    },
  ],
})
export class GraphQLModule {}
