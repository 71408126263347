import { Injectable, isDevMode } from '@angular/core';
import { Langauge } from '@enums/lang.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  get availableLanguages() {
    const langs: Langauge[] = [];
    Object.keys(Langauge).forEach((key) => {
      langs.push(Langauge[key as keyof typeof Langauge]);
    });
    return langs;
  }

  constructor(private readonly translate: TranslateService) {}

  useLanguage(lang: string): Subscription {
    return this.translate.use(lang).subscribe({
      next: () => {
        this.setLangToHtmlTag(lang);
        if (isDevMode()) console.info(`'${lang.toUpperCase()}' language initialized`);
      },
      error: (err) => {
        if (isDevMode()) {
          console.error(`Problem with '${lang}' language initialization.'`);
          console.error(err);
        }
      },
    });
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  getInitLanguage(): Langauge {
    return Langauge.PL;
  }

  setLangToHtmlTag(lang: string) {
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  }

  isValidLanguage(testingLanguage: Langauge): boolean {
    return !!this.availableLanguages.filter((lang) => lang === testingLanguage).length;
  }
}
